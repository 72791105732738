





























































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { DateTime } from 'luxon'

import MasterMyLessonCard from '@/components/cards/MasterMyLessonCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterLessonsModule from '@/store/modules/master/lessons'
import {
  FilterLessonStatus,
  LessonReviewResource,
  MasterEducationMasterGroupIdLessonsGetParams,
  MasterLessonShortResource,
  NameValueResource,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import RatedLessonModal from '@/components/modals/lessons/RatedLessonModal.vue'
import MasterEducationModule from '@/store/modules/master/education'
import MasterTutorialsModule from '@/store/modules/master/tutorials'

@Component({
  components: {
    MasterMyLessonCard,
    RatedLessonModal,
    Select,
  },
})
export default class LessonsList extends Mixins(MasterGroupMixin, NotifyMixin) {
  private breadcrumbs = [{ name: 'Курсы', path: '/master/courses/my' }]
  private review: LessonReviewResource | null = null
  private showModalRated = false
  private statuses: NameValueResource[] = [
    { name: 'Все', value: FilterLessonStatus.ALL },
    { name: 'Просмотренные', value: FilterLessonStatus.CHECKED },
    { name: 'Непросмотренные', value: FilterLessonStatus.NOT_CHECKED },
  ]

  private get lessons(): MasterLessonShortResource[] {
    return MasterLessonsModule.lessons
  }

  private get filter() {
    return MasterLessonsModule.lessonsFilter
  }

  private set filter(filter: MasterEducationMasterGroupIdLessonsGetParams) {
    MasterLessonsModule.setLessonsFilter(filter)

    this.fetchLessons()
  }

  private get user () {
    return {
      avatar: AuthModule.self?.avatar,
      miniTutorialPassed: AuthModule.self?.miniTutorialPassed,
      name: AuthModule.self?.name,
      surname: AuthModule.self?.surname,
    }
  }

  private get previousMasterGroupID() {
    return MasterEducationModule.previousMasterGroupID
  }

  private get isTourStart() {
    return MasterTutorialsModule.lessonExerciseTutorialActive
  }

  private mounted() {
    const main: HTMLDivElement = document.querySelector('.v-main') as HTMLDivElement
    if (main) {
      main.style.minHeight = 'calc(var(--vh, 1vh) * 100)'
    }

    if (!this.filter.monthId || this.previousMasterGroupID !== this.currentMasterGroupID) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
      MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    } else {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number, // MY_STEP: 3 Записываем из геттера
      }
    }
    this.startTour()
  }

  private startTour() {
    // Определяем текущий месяц
    const currentMonth = DateTime.now().toFormat('yyyy-MM')
    // Количество купленных месяцев
    const countMonths = this.currentMasterGroup.months.length
    // Определяем первый месяц активной группы
    const firstMonths = this.currentMasterGroup.months.length ? DateTime.fromSQL(this.currentMasterGroup.months[0].name).toFormat('yyyy-MM') : ''
    // Мастер прошёл туториал
    const miniTutorialPassed = !!this.user.miniTutorialPassed

    if (!this.isTourStart && !miniTutorialPassed && countMonths > 1 && firstMonths && currentMonth > firstMonths) {
      this.$bus.$emit('startLessonExerciseTour', 'lesson')
    }
  }

  private handleReview(lesson: MasterLessonShortResource) {
    this.review = {
      checkedAt: '',
      comment: lesson.comment as string,
      ratedAt: lesson.ratedAt as string,
      rating: lesson.rating as number,
      user: this.user as any,
    }
    this.showModalRated = true
  }

  private handleFilter(field: keyof MasterEducationMasterGroupIdLessonsGetParams, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }

    if (field === 'monthId') MasterEducationModule.setSavedMasterGroupMounthID(value)
  }


  @Bind
  @Debounce(300)
  private fetchLessons() {
    if (this.currentMasterGroupID && this.currentMasterGroupID !== -1)
      MasterLessonsModule.fetchLessons({ masterGroupID: this.currentMasterGroupID, params: this.filter })
        .catch(this.notifyError)
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroup () {
    MasterEducationModule.setSavedMasterGroupMounthID(null)
    MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    if (this.currentMasterGroupMonth) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
    }
  }

  private destroyed() {
    const main: HTMLDivElement = document.querySelector('.v-main') as HTMLDivElement
    if (main) {
      main.style.minHeight = ''
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Уроки',
    }
  }
}
